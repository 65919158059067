import React from "react"
import SEO from "../components/seo"
import Header from "../components/header"
import Testimonials from "../components/testimonials"
import FAQ from "../components/faq"
import Studies from "../components/ClinicalStudies"
import { useStaticQuery, graphql } from "gatsby"
import Checkmark from "../images/checkmark.svg"
import ImgIV from "../images/hiw.jpg"
import OneSvg from "../images/one.svg"
import TwoSvg from "../images/two.svg"
import ThreeSvg from "../images/three.svg"
import FourSvg from "../images/four.svg"
import FiveSvg from "../images/five.svg"




const subheader = <h3 className='text-white mt-2 mb-2'>At Las Vegas Ketamine IV Solution Centers, we apply clinically proven IV ketamine therapy for treatment of depression, anxiety, PTSD and other ailments. Contact us today for a free consultation and start changing your life for the better!</h3>
const CheckMark = () => <><img style={{ width: 20, paddingBottom: '5px', marginRight: 5 }} src={Checkmark} alt='checkmark' /></>
const ImageIV = () => <><img className='hiw-img with-shadow' src={ImgIV} alt='Girl with a flower' style={{borderRadius: 16}} /></>
const BulletOne = () => <><img className='bullets' src={OneSvg} alt='numeber one' /></>
const BulletTwo = () => <><img className='bullets' src={TwoSvg} alt='numeber two' /></>
const BulletThree = () => <><img className='bullets' src={ThreeSvg} alt='numeber three' /></>
const BulletFour = () => <><img className='bullets' src={FourSvg} alt='numeber four' /></>
const BulletFive = () => <><img className='bullets' src={FiveSvg} alt='numeber five' /></>

const getUrlParams = (search) => {
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  let params = {}
  // eslint-disable-next-line 
  hashes.map(hash => { 
      let [key, val] = hash.split('=')
      params[key] = decodeURIComponent(val)
  })

  return params
}

const dynamicHeader = (pageHeader) => {
  if (typeof window !== `undefined`) {
  const headline =  getUrlParams(window.location.search).headline
  let header
    if(typeof headline !== `undefined`) {
      let headerArr = headline.toString().split(" ")
      let headerCap = []
      headerArr.forEach((el) => headerCap.push(el.charAt(0).toUpperCase() + el.substring(1)))
      header = headerCap.join(" ")
    }else {
      header = pageHeader
    }
  return header
  }
}


const HomePage = () => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)
  return (
    <>
      <SEO title="Home" />
      <Header
        siteTitle={data.site.siteMetadata.title}
        className='hero-wrapper'
        pageHeader={dynamicHeader('Ketamine Centers of Las Vegas')}
        subheader={subheader}
      />
      <section className="section-theming bg-white" id="treatment">
        <h2 className="is-purple p-4 header-allignment" >Clinically Proven IV Treatment for Depression, Anxiety, PTSD and more.</h2>
        <div className="container p-4">
          <div className="row">
            <p className="col-sm">
              IV Solution and Ketamine Centers offer clinically proven customized intravenous ketamine therapies in state-of-the-art medical facilities. Experience life-changing therapy in private,
              confidential settings with anesthesiologists, registered nurses, and skilled staff onsite.
              </p>
            <p className="col-sm">We bring this proven therapy to patients in a safe and comfortable outpatient setting,
              providing ketamine infusion therapy services using effective protocols in accordance with the highest industry standards. Our team is committed to providing the best patient outcomes
              to improve quality of life and well-being.
              </p>
          </div>
        </div>
      </section>
      <section className="section-theming bg-white">
        <div className="container">
          <div className="row">
            <div className="col-sm pb-4" style={{ background: '#DDE2F1' }}>
              <h2 className="is-purple p-4 header-allignment">Conditions we treat</h2>
              <p className="pl-4 pr-4">
                Our premier clinic offers innovative and effective intravenous medical treatment for a wide range of treatment-resistant neurological conditions.
                As the top ketamine treatment center in Las Vegas, you can expect state of the art care from a team of experienced medical professionals and physicians.
              </p>
            </div>
            <div className="col-sm pb-4" style={{ background: '#E8EAF0' }}>
              <h2 className="is-purple p-4 header-allignment">We can help with:</h2>
              <table className="is-purple conditions">
                <tbody>
                  <tr>
                    <td className="pr-4"><CheckMark />Depression</td>
                    <td><CheckMark />Fibromyalgia</td>
                  </tr>
                  <tr>
                    <td className="pr-4"><CheckMark />Anxiety</td>
                    <td><CheckMark />Chronic Pain</td>
                  </tr>
                  <tr>
                    <td className="pr-4"><CheckMark />PTSD</td>
                    <td><CheckMark />OCD</td>
                  </tr>
                  <tr><td className="pt-3" style={{ textAlign: 'center' }}>And more…</td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section className="section-theming bg-white" id="what-to-expect">
        <div className="how-it-works d-flex align-items-center justify-content-center">
          <div className="container col-8">
            <h2 className="pt-4">What to Expect</h2>
            <p className="pt-4 is-white">Learn more about our process and what to expect during treatment</p>
          </div>
          <div className="hiw-img-device mr-3"></div>
        </div>
        <div style={{ position: 'relative' }}>
          <ImageIV />
        </div>
        <div className="bg-gray">
          <div className="container wide-80 no-gutter-left--desktop">
            <div className="row">
              <div className="col-sm-5">
                <div className="p-4 d-flex align-items-center bullet-alignment">
                  <BulletOne />
                  <h2 className="is-purple p-4 header-allignment hiw-ml-neg">Consultation</h2>
                </div>
              </div>
              <div className="col-sm mr-4">
                <p className="is-purple p-4">
                  Everything starts with a phone or in-office consultation with one of our friendly and highly trained medical staff.
                  During the consultation, we will learn about your symptoms and medical history to accurately determine if you are a
                  candidate for ketamine IV therapy. We know you will have questions too, so we will answer any questions you may have
                  about ketamine treatment right then and there.
                </p>
              </div>
            </div>
            <div className="row p-4-desktop">
              <div className="col-sm-5">
                <div className="p-4 d-flex align-items-center bullet-alignment">
                  <BulletTwo />
                  <h2 className="is-purple p-4 header-allignment hiw-ml-neg">Treatment <br /> Day</h2>
                </div>
              </div>
              <div className="col-sm">
                <p className="is-purple p-4">
                  Upon arrival, you will check in and then be taken back to the private infusion room to begin treatment.
                  You will be comfortably reclining or laying down for the 45 minute duration of the infusion and you will
                  wear a blood pressure cuff and other standard monitors.
                </p>
              </div>
            </div>
            <div className="row p-4-desktop">
              <div className="col-sm-5">
                <div className="p-4 d-flex align-items-center bullet-alignment">
                  <BulletThree />
                  <h2 className="is-purple p-4 header-allignment hiw-ml-neg">During <br /> Treatment</h2>
                </div>
              </div>
              <div className="col-sm">
                <p className="is-purple p-4">
                  After the infusion starts, you will almost immediately feel a little drowsy and will start feeling “free” or “floating”.
                  You will be comfortably reclining or laying down for the 45 minute duration of the infusion and you will wear a blood pressure
                  cuff and other standard monitors.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container wide-80 no-gutter-left--desktop">
          <div className="row p-4-desktop">
            <div className="col-sm-5">
              <div className="p-4 d-flex align-items-center bullet-alignment">
                <BulletFour />
                <h2 className="is-purple p-4 header-allignment hiw-ml-neg">After <br /> Treatment</h2>
              </div>
            </div>
            <div className="col-sm">
              <p className="is-purple p-4">
                Once the infusion is over, your recovery will be around 30-45 minutes.  Anticipate drowsiness for a few hours after
                the infusion. We strongly recommend that you pre-arrange a ride home after your treatment.
              </p>
            </div>
          </div>
          <div className="row p-4-desktop">
            <div className="col-sm-5">
              <div className="p-4 d-flex align-items-center bullet-alignment">
                <BulletFive />
                <h2 className="is-purple p-4 header-allignment hiw-ml-neg">Results <br /> of Infusion</h2>
              </div>
            </div>
            <div className="col-sm">
              <p className="is-purple p-4">
                Patient&rsquo;s length of response to the infusion will vary from patient to patient but will increase with subsequent treatments.
                For example, after the first series of six infusions for depression, you may experience relief of symptoms for 1-2
                months and for up to  6 months.  Then as needed, we will schedule single booster infusions to control your symptoms.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <FAQ />
      <Studies />
    </>
  )
}

export default HomePage
