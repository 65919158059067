
import PropTypes from "prop-types"
import React from "react"
import ContactForm from "./Form"



const Header = ({ pageHeader, className, subheader }) => (
  <header>
    <div className={`${className}`}>
      <div className="container">
        <div className="row">
          <div className='hero col-lg-5'>
            <h1 className="pt-2 pb-3">{pageHeader}</h1>
            {subheader}
          </div>
          <div className="quote-wrapper col-lg-6 mt-2 mt-md-0">
              <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  pageHeader: PropTypes.string,
  className: PropTypes.string
}

Header.defaultProps = {
  pageHeader: ``,
  className: ``
}

export default Header
