
import React, { Component } from "react"
import DocImg from "../images/doc.svg"

class Studies extends Component {

    constructor(props) {
        super(props);
        this.state = { loadMore: false }
        this.handleClick = this.handleClick.bind(this);
    }

    // function to handle the click
    handleClick() {
        this.setState({
            loadMore: this.state.loadMore ? false : true
        });

        setTimeout(() => {
            const morePosts = document.getElementById('showMore')
            const lessPosts = document.getElementById('clinical-studies')
            morePosts ? morePosts.scrollIntoView({ behavior: 'smooth' }) : lessPosts.scrollIntoView({ behavior: 'smooth' })
        }, 100)
    };

    // the render() method to put stuff into the DOM
    render() {
        const DocIcon = () => <><img style={{ width: 35 }} src={DocImg} alt="document icon" /></>
        const Studies = () => (
            <section className="section-theming bg-purple pb-4" id="clinical-studies">
                <div className="container p-4 is-purple" id="studies">
                    <h2 className="is-white p-4 header-allignment" >Clinical Studies and Other Resources</h2>
                    <div className="row">
                        <div className="col-sm is-study p-4">
                            <a href="https://chicagoivsolution.com/wp-content/uploads/2019/11/Effect-of-SGB-Treatment-on-PTSD.pdf" rel="noopener noreferrer" target="_blank">
                                <div className="icon-doc d-flex justify-content-center">
                                    <DocIcon />
                                </div>
                                <h3 className="pt-4">  Effect of SGB Treatment on PTSD </h3>
                                <p className="is-white">A Randomized Clinical Trial <br />JAMA (Nov 2019)</p>
                            </a>
                        </div>
                        <div className="col-sm is-study p-3">
                            <a href="https://www.psychologytoday.com/us/blog/free-range-psychology/201908/new-promise-treating-ptsd?eml" rel="noopener noreferrer" target="_blank">
                                <div className="icon-doc d-flex justify-content-center">
                                    <DocIcon />
                                </div>
                                <h3 className="pt-4">  New Promise for Treating PTSD - Psychology Today</h3>
                                <p className="is-white">An injection may give people a "second shot at a happy life."<br />(August 2019)</p>
                            </a>
                        </div>
                        <div className="col-sm is-study p-3">
                            <a href="https://www.liebertpub.com/doi/10.1089/cap.2018.0030" rel="noopener noreferrer" target="_blank">
                                <div className="icon-doc d-flex justify-content-center">
                                    <DocIcon />
                                </div>
                                <h3 className="pt-4">   Intravenous Ketamine for Adolescents with Treatment-Resistant Depression: </h3>
                                <p className="is-white">Journal of Child and Adolescent Psychopharmacology <br /> (July 2018)</p>
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm is-study p-3">
                            <a href="https://ajp.psychiatryonline.org/doi/full/10.1176/appi.ajp.2017.17060647" rel="noopener noreferrer" target="_blank">
                                <div className="icon-doc d-flex justify-content-center">
                                    <DocIcon />
                                </div>
                                <h3 className="pt-4">   Ketamine for Rapid Reduction of Suicidal Thoughts in Major Depression  </h3>
                                <p className="is-white">American Journal of Psychiatry <br /> (Dec 2017)</p>
                            </a>
                        </div>
                        <div className="col-sm is-study p-3">
                            <a href="http://asaabstracts.com/strands/asaabstracts/abstract.htm?year=2017&index=18&absnum=3491" rel="noopener noreferrer" target="_blank">
                                <div className="icon-doc d-flex justify-content-center">
                                    <DocIcon />
                                </div>
                                <h3 className="pt-4">  The Use of Ketamine Infusions for Refractory Headaches </h3>
                                <p className="is-white"> A Retrospective Analysis – Am Society of Anesthesiologists <br />(Oct 2017) </p>
                            </a>
                        </div>
                        <div className="col-sm is-study p-3">
                            <a href="https://www.sciencedaily.com/releases/2017/07/170724090749.htm" rel="noopener noreferrer" target="_blank">
                                <div className="icon-doc d-flex justify-content-center">
                                    <DocIcon />
                                </div>
                                <h3 className="pt-4">  World-First Ketamine Trial Shows Promise For Geriatric Depression </h3>
                                <p className="is-white">American Journal of Geriatric Psychiatry <br /> (July 2017)</p>
                            </a>
                        </div>
                    </div>
                    {
                        !this.state.loadMore &&
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-secondary load-more" onClick={this.handleClick} >Show More</button>
                        </div>
                    }
                    {
                        this.state.loadMore && <div>
                            <div className="row" id="showMore">
                                <div className="col-sm is-study p-3">
                                    <a href="https://www.medpagetoday.com/psychiatry/depression/65255" rel="noopener noreferrer" target="_blank">
                                        <div className="icon-doc d-flex justify-content-center">
                                            <DocIcon />
                                        </div>
                                        <h3 className="pt-4"> FDA Data Support Ketamine as Depression Therapy </h3>
                                        <p className="is-white">MedPage Today <br />(May 2017)</p>
                                    </a>
                                </div>
                                <div className="col-sm is-study p-3">
                                    <a href="http://jamanetwork.com/journals/jamapsychiatry/fullarticle/1860851" rel="noopener noreferrer" target="_blank">
                                        <div className="icon-doc d-flex justify-content-center">
                                            <DocIcon />
                                        </div>
                                        <h3 className="pt-4">  Efficacy of Intravenous Ketamine for Treatment of Chronic Posttraumatic Stress Disorder </h3>
                                        <p className="is-white">JAMA (June 2014)</p>
                                    </a>
                                </div>
                                <div className="col-sm is-study p-3">
                                    <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3992936/" rel="noopener noreferrer" target="_blank">
                                        <div className="icon-doc d-flex justify-content-center">
                                            <DocIcon />
                                        </div>
                                        <h3 className="pt-4">  Antidepressant Efficacy of Ketamine in Treatment-Resistant Major Depression </h3>
                                        <p className="is-white">A Two-Site Randomized Controlled Trial – Am J Psychiatry <br />(Oct 2013)</p>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm is-study p-3">
                                    <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3725185//" rel="noopener noreferrer" target="_blank">
                                        <div className="icon-doc d-flex justify-content-center">
                                            <DocIcon />
                                        </div>
                                        <h3 className="pt-4"> Rapid and Longer-Term Antidepressant Effects of Repeated Ketamine Infusions in Treatment-Resistant Major Depression</h3>
                                        <p className="is-white">Biological Psychiatry <br />(Aug 2013)</p>
                                    </a>
                                </div>
                                <div className="col-sm is-study p-3">
                                    <a href="https://www.ncbi.nlm.nih.gov/pubmed/23428794" rel="noopener noreferrer" target="_blank">
                                        <div className="icon-doc d-flex justify-content-center">
                                            <DocIcon />
                                        </div>
                                        <h3 className="pt-4">Serial infusions of low-dose ketamine for major depression </h3>
                                        <p className="is-white">Journal of Psychopharmacology <br />(May 2013)</p>
                                    </a>
                                </div>
                                <div className="col-sm is-study p-3">
                                    <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3673880/" rel="noopener noreferrer" target="_blank">
                                        <div className="icon-doc d-flex justify-content-center">
                                            <DocIcon />
                                        </div>
                                        <h3 className="pt-4">  Ketamine as a Novel Antidepressant </h3>
                                        <p className="is-white">From Synapse to Behavior – Clinical Pharmacology &amp; Therapeutics <br />(Dec 2011)</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.loadMore &&
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-secondary load-more" onClick={this.handleClick} >Show Less</button>
                        </div>
                    }
                </div>
            </section>
        )
        return (
            <Studies />
        );
    }

}
export default Studies;